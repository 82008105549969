import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"

const Grid = styled.div`
  display: grid;
  padding: 0 24px;
  margin-bottom: 74px;
  gap: 46px;
  @media (min-width: ${breakpoints.lg}) {
    gap: 60px;
    grid-template-columns: 1fr 1fr;
  }
`

const ImageWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;

  @media(min-width: ${breakpoints.lg}) {
    max-width: unset;
  }
`

const Column = styled.div`
  position: relative;
`

const Content = styled.div`
  h2 {
    ${fonts.avantGardeBold};
    color: ${colors.blue};
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: auto;
    margin-bottom: 15px;
  }

  h3 {
    ${fonts.avantGardeBold};
    color: ${colors.blue};
    font-size: 24px;
    margin-bottom: 15px;
  }

  hr {
    width: 20px;
    background: ${colors.blue};
    height: 3px;
    margin: 20px auto;
    border: none;
    text-align: center;
  }

  p {
    ${fonts.avantGardrLight};
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }

    hr {
      margin: 30px auto;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    h3 {
      font-size: 24px;
    }
  }
`

const Intro = ({ image, founderCeoBio }) => {
  return (
    <Container>
      <Grid>
        <Column>
          <ImageWrapper>
            <Img fluid={image.fluid} alt={image.title} />
          </ImageWrapper>
        </Column>
        <Column>
          <Content>{documentToReactComponents(founderCeoBio.json)}</Content>
        </Column>
      </Grid>
    </Container>
  )
}

export default Intro
