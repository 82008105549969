import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import UpNext from "../components/Core/UpNext"
import { graphql } from "gatsby"
import Intro from "../components/About/Intro"
import Hero from "../components/About/Hero"

const About = ({ location, data }) => {
  const path = location.pathname

  const { page } = data

  const {
    heroImage,
    heroContent,
    heroTitle,
    image,
    founderCeoBio,
    upNext,
  } = page

  return (
    <Layout>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        path={path}
      />
      <Hero background={heroImage} content={heroContent} title={heroTitle} />
      <Intro image={image} founderCeoBio={founderCeoBio} />
      <UpNext
        title={upNext.name}
        link={upNext.ctaLink}
        text={upNext.ctaLabel}
        image={upNext.image}
        alt={upNext.image.title || ""}
      />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    page: contentfulAboutPage {
      metaTitle
      metaDescription
      heroTitle {
        json
      }
      heroContent {
        json
      }
      heroImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      founderCeoBio {
        json
      }
      image {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      upNext {
        image {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        ctaLabel
        ctaLink
        name
      }
    }
  }
`
