import React from "react"
import { Col, Container, Row } from "styled-bootstrap-grid"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Button from "../Core/Button"
import Img from "gatsby-image"

const ProjectsContainer = styled(Container)`
  background: ${colors.blue};
  margin-top: 40px;
  @media (min-width: ${breakpoints.md}) {
    height: 459px;
  }
`

const ColLeft = styled(Col)`
  @media (min-width: ${breakpoints.sm}) {
    padding-top: 265px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding-top: 0;
  }
`

const ColRight = styled(Col)`
  @media (min-width: ${breakpoints.sm}) {
    position: absolute;
    width: 100%;
    right: 0;
  }
`

const ImageBlock = styled.div`
  height: 265px;
  width: 100%;
  overflow: hidden;
  div {
    height: 100%;
  }
  @media (min-width: ${breakpoints.md}) {
    height: 459px;
  }
`

const Content = styled.div`
  padding: 79px 33px 78px 33px;
  @media (min-width: ${breakpoints.md}) {
    padding: 151px 0 150px 0;
  }
`

const Title = styled.div`
  color: ${colors.white};
  ${fonts.avantGardeBold};
  font-size: 36px;
  text-transform: lowercase;
  padding-top: 25px;
  padding-bottom: 32px;
`

const Text = styled.div`
  color: ${colors.white};
  text-transform: uppercase;
  ${fonts.avantGardeMedium};
  font-size: 12px;
  letter-spacing: 2px;
`

const UpNext = ({ title, link, text, image, alt }) => {
  return (
    <ProjectsContainer fluid>
      <Container>
        <Row>
          <ColRight md={6} smOrder={1} mdOrder={2}>
            <ImageBlock>
              {/* TODO - replace static image */}
              <Img
                fluid={
                  image.childImageSharp
                    ? image.childImageSharp.fluid
                    : image.fluid
                }
                alt={alt}
              />
            </ImageBlock>
          </ColRight>
          <ColLeft md={6} smOrder={2} mdOrder={1}>
            <Content>
              <Text>Up next</Text>
              <Title>{title}</Title>
              <Button link={link}>{text}</Button>
            </Content>
          </ColLeft>
        </Row>
      </Container>
    </ProjectsContainer>
  )
}

export default UpNext
