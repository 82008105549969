import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { Col, Container, Row } from "styled-bootstrap-grid"
import BackgroundImage from "../../resources/img/about/hero_image.jpg"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const HeroBlock = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (min-width: ${breakpoints.lg}) {
    height: 810px;
  }
`
const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 60px;
  height: inherit;
  @media (min-width: ${breakpoints.lg}) {
    padding: 80px 20px 150px;
    background: url(${BackgroundImage}) no-repeat;
    background-size: contain;
    background-origin: content-box;
    background-position: right center;
    max-width: 1440px;
  }
`
const RowStyled = styled(Row)`
  position: relative;
  padding: 10px;
  @media (min-width: ${breakpoints.md}) {
    padding-left: 10%;
  }
`

const HeroImageMobile = styled(Img)`
  max-width: 696px;
`
const ColStyled = styled(Col)`
  margin-top: -24%;
  z-index: -1;
  padding-bottom: 20px;
  @media (min-width: ${breakpoints.sm}) {
    max-width: 400px;
    position: absolute;
    margin: 0;
    top: 40px;
    right: 10px;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const StyledContent = styled.div`
  ${fonts.avantGardeBold};
  color: ${colors.blue};
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  max-width: 380px;

  b {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.blue};
    -webkit-text-stroke-width: 1px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 50px;
    line-height: 62px;
    max-width: 700px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 60px;
    line-height: 72px;
    max-width: 770px;
  }
`

const StyledParagraph = styled.div`
  ${fonts.avantGardrLight};
  font-size: 16px;
  line-height: 24px;
  padding: 20px 0;
  max-width: 380px;
  @media (min-width: ${breakpoints.lg}) {
    max-width: 320px;
    padding-top: 60px;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding-top: 20px;
  }
`

const Hero = ({ background, content, title }) => {
  const options = {
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }
  return (
    <HeroBlock fluid>
      <ContentContainer>
        <RowStyled>
          <Col>
            <StyledContent>
              {documentToReactComponents(title.json, options)}
            </StyledContent>
          </Col>
          <ColStyled>
            <HeroImageMobile fluid={background.fluid} />
          </ColStyled>
          <Col>
            <StyledParagraph>
              {documentToReactComponents(content.json, options)}
            </StyledParagraph>
          </Col>
        </RowStyled>
      </ContentContainer>
    </HeroBlock>
  )
}

export default Hero
